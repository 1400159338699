import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Alert } from '@mui/material';

export default function OrderAlerts({ open, setOpen, data }) {
  
    const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={()=>setOpen(false)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={()=>setOpen(false)}
      >
        <Alert
            onClose={()=>setOpen(false)}
            severity="success"
            variant="filled"
            sx={{ width: '100%' }}
        >
            {`${data?.userName} ordered ${data?.productName} recently.`}
        </Alert>
      </Snackbar>
    </div>
  );
}