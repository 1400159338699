/* eslint-disable no-redeclare */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode"

const baseURL = process.env.REACT_APP_BACKEND_URL;

const initialState = {
    shippingCharges: [],
    responseStatus: "",
    responseMessage: "",
};

export const createShippingCharge = createAsyncThunk(
    "shippingCharges/createShippingCharge",
    async (shippingCharge, { rejectWithValue }) => {
        try {
            const token = Cookies.get("studioSasvatLoggedInUserToken");
            const decodedData = jwtDecode(token);
            const user_id = decodedData?.id;

            const response = await axios.post(`${baseURL}/shipping-charge`, JSON.stringify(shippingCharge), {
                headers: {
                    "x-authorization": `Bearer ${user_id}`,
                    "Content-Type": "application/json"
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data.message);
        }
    }
);

export const getShippingCharges = createAsyncThunk(
    "shippingCharges/getShippingCharges",
    async () => {
        try {
            const response = await axios.get(`${baseURL}/shipping-charge`);
            return response.data;
        } catch (error) {
            return error.response.data.message;
        }
    }
);

export const getShippingCharge = createAsyncThunk(
    "shippingCharges/getShippingCharge",
    async (shippingChargeId, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${baseURL}/shipping-charge/${shippingChargeId}`
            );
            return response.data;
        } catch (error) {
            return error.response.data.message;
        }
    }
);

export const updateShippingCharge = createAsyncThunk(
    "shippingCharges/updateShippingCharge",
    async (shippingCharge, { rejectWithValue }) => {
        try {
            const token = Cookies.get("studioSasvatLoggedInUserToken");
            const decodedData = jwtDecode(token);
            const user_id = decodedData?.id;
            
            const response = await axios.put(
                `${baseURL}/shipping-charge/${shippingCharge?.id}`,
                shippingCharge,
                {
                    headers: {
                        "x-authorization": `Bearer ${user_id}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data.message);
        }
    }
);

export const deleteShippingCharge = createAsyncThunk(
    "shippingCharges/deleteShippingCharge",
    async (shippingChargeId, { rejectWithValue }) => {
        try {
            const token = Cookies.get("studioSasvatLoggedInUserToken");
            const decodedData = jwtDecode(token);
            const user_id = decodedData?.id;

            const response = await axios.delete(
                `${baseURL}/shipping-charge/${shippingChargeId}`,
                {
                    headers: {
                        "x-authorization": `Bearer ${user_id}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            return response.data;
        } catch (error) {
            return error.response.data.message;
        }
    }
);


const shippingChargesSlice = createSlice({
    name: "shippingCharges",
    initialState,
    reducers: {
        resetShippingChargeState: (state) => initialState,
    },
    extraReducers: (builder) => {
        // create reducers
        builder
        .addCase(createShippingCharge.pending, (state) => {
            state.responseStatus = "pending";
        })
        .addCase(createShippingCharge.fulfilled, (state) => {
            state.responseStatus = "success";
            state.responseMessage = "Shipping Charge created successfully";
        })
        .addCase(createShippingCharge.rejected, (state, action) => {
            state.responseStatus = "rejected";
            state.responseMessage = action?.payload;
        });

        // get all reducers
        builder
        .addCase(getShippingCharges.pending, (state) => {
            state.responseStatus = "pending";
        })
        .addCase(getShippingCharges.fulfilled, (state, action) => {
            state.shippingCharges = action.payload;
            state.responseStatus = "success";
            state.responseMessage = "Get All";
        })
        .addCase(getShippingCharges.rejected, (state, action) => {
            state.responseStatus = "rejected";
            state.responseMessage = action.payload;
        });

        // get reducers
        builder
        .addCase(getShippingCharge.pending, (state) => {
            state.responseStatus = "pending";
        })
        .addCase(getShippingCharge.fulfilled, (state, action) => {
            state.shippingCharges = action.payload;
            state.responseStatus = "success";
            state.responseMessage = "Get Single";
        })
        .addCase(getShippingCharge.rejected, (state, action) => {
            state.responseStatus = "rejected";
            state.responseMessage = action.payload;
        });

        // update reducers
        builder
        .addCase(updateShippingCharge.pending, (state) => {
            state.responseStatus = "pending";
        })
        .addCase(updateShippingCharge.fulfilled, (state, action) => {
            if (Array.isArray(state.shippingCharges)) {
            state.shippingCharges = state.shippingCharges.map((shippingCharge) =>
                shippingCharge.id === action.payload._id ? action.payload : shippingCharge
            );
            } else {
            state.shippingCharges = action.payload;
            }
            state.responseStatus = "success";
            state.responseMessage = "Shipping Charge updated successfully";
        })
        .addCase(updateShippingCharge.rejected, (state, action) => {
            state.responseStatus = "rejected";
            state.responseMessage = action?.payload;
        });

         // delete reducers
        builder
        .addCase(deleteShippingCharge.pending, (state) => {
            state.responseStatus = "pending";
        })
        .addCase(deleteShippingCharge.fulfilled, (state) => {
            state.responseStatus = "success";
            state.responseMessage = "Shipping Charge deleted successfully";
        })
        .addCase(deleteShippingCharge.rejected, (state, action) => {
            state.responseStatus = "rejected";
            state.responseMessage = action?.payload?.message;
        });
    },
});

export const { resetShippingChargeState } = shippingChargesSlice.actions;
export default shippingChargesSlice.reducer;