import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './Pages/FrontPages/Home'
import Footer from './Components/Layout/Footer'
import ProductDetail from './Pages/FrontPages/ProductDetail'
import Cart from './Pages/FrontPages/Cart'
import Checkout from './Pages/FrontPages/Checkout'
import Wishlist from './Pages/FrontPages/Wishlist'
import MyOrders from './Pages/FrontPages/MyOrders'
import Login from './Pages/FrontPages/Login'
import DashboardIndex from './Pages/AuthPages/Dashboard/Index'
import ProductList from './Pages/AuthPages/Product/List'
import ProductForm from './Pages/AuthPages/Product/Form'
import OrderList from './Pages/AuthPages/Orders/List'
import AllProducts from './Pages/FrontPages/AllProducts'
import MyProfile from './Pages/FrontPages/MyProfile'
import HelpCenter from './Pages/FrontPages/HelpCenter'
import RelatedProducts from './Pages/FrontPages/RelatedProducts'
import EditProfile from './Pages/FrontPages/EditProfile';
import MyOrderDetail from './Pages/FrontPages/MyOrderDetail';
import PrivacyPolicy from './Pages/FrontPages/PrivacyPolicy';
import ShippingAndDelivery from './Pages/FrontPages/ShippingAndDelivery';
import TermsAndCondition from './Pages/FrontPages/TermsAndCondition';
import ReturnAndRefundPolicy from './Pages/FrontPages/ReturnAndRefundPolicy';
import ContactUs from './Pages/FrontPages/ContactUs';
import MobileApplication from './Pages/FrontPages/MobileApplication';
import OrderDetail from './Pages/AuthPages/Orders/Detail';
import ForgetPassword from './Pages/FrontPages/ForgetPassword';
import CategoryList from './Pages/AuthPages/Category/List';
import CategoryForm from './Pages/AuthPages/Category/Form';
import AllCategories from './Pages/FrontPages/AllCategory';
import SelectedCategoryProducts from './Pages/FrontPages/SelectedCategoryProducts';
import MainBannerList from './Pages/AuthPages/MainBanner/List';
import MainBannerForm from './Pages/AuthPages/MainBanner/Form';
import SecondaryBannerList from './Pages/AuthPages/SecondaryBanner/List';
import SecondaryBannerForm from './Pages/AuthPages/SecondaryBanner/Form';
import AboutUs from './Pages/FrontPages/AboutUs';
import SubCategoryList from './Pages/AuthPages/SubCategory/List';
import SubCategoryForm from './Pages/AuthPages/SubCategory/Form';
import SelectedSubCategoryProducts from './Pages/FrontPages/SelectedSubCategoryProducts';
import SiteRatingButton from './Components/Layout/SiteRatingButton';
import UserList from './Pages/AuthPages/Users/List';
import UserAllOrders from './Pages/AuthPages/Users/Detail';
import CouponList from './Pages/AuthPages/Coupon/List';
import CouponForm from './Pages/AuthPages/Coupon/Form';
import ShippingChargesList from './Pages/AuthPages/ShippingCharges/List';
import { useEffect, useState } from 'react';
import OrderAlerts from './Components/Alerts/OrderAlerts';

function App() {

  const [showAlert, setShowAlert] = useState(false)
  const [recordShowDone, setRecordShowDone] = useState([])
  const [displayData, setDisplayData] = useState()

  const [fakeOrders, setFakeOrders] = useState([
    { id: 1, productName: 'Resin Hung', userName: 'Sunil Mishra' },
    { id: 2, productName: 'Resin Coasters', userName: 'Aarti Sharma' },
    { id: 3, productName: 'Resin Wall Art', userName: 'Ravi Verma' },
    { id: 4, productName: 'Resin Jewelry Box', userName: 'Kavita Gupta' },
    { id: 5, productName: 'Resin Earrings', userName: 'Suman Joshi' },
    { id: 6, productName: 'Resin Keychains', userName: 'Rajesh Mehta' },
    { id: 7, productName: 'Resin Necklace Pendants', userName: 'Nidhi Chawla' },
    { id: 8, productName: 'Resin Charms', userName: 'Sunil Yadav' },
    { id: 9, productName: 'Resin Rings', userName: 'Rashmi Bansal' },
    { id: 10, productName: 'Resin Bracelets', userName: 'Kiran Kapoor' },
    { id: 11, productName: 'Resin Desk Organizer', userName: 'Vikram Malhotra' },
    { id: 12, productName: 'Resin Phone Stands', userName: 'Priya Sen' },
    { id: 13, productName: 'Resin Paperweights', userName: 'Aman Jain' },
    { id: 14, productName: 'Resin Hair Clips', userName: 'Preeti Agarwal' },
    { id: 15, productName: 'Resin Candle Holders', userName: 'Ramesh Patel' },
    { id: 16, productName: 'Resin Bookmarks', userName: 'Anita Mishra' },
    { id: 17, productName: 'Resin Picture Frames', userName: 'Ajay Kumar' },
    { id: 18, productName: 'Resin Flower Preserves', userName: 'Shalini Reddy' },
    { id: 19, productName: 'Resin Chess Board', userName: 'Rohit Sharma' },
    { id: 20, productName: 'Resin Dice Sets', userName: 'Meena Iyer' },
    { id: 21, productName: 'Resin Magnets', userName: 'Vishal Khanna' },
    { id: 22, productName: 'Resin Plant Holders', userName: 'Pooja Nair' },
    { id: 23, productName: 'Resin Bottle Openers', userName: 'Sanjay Sinha' },
    { id: 24, productName: 'Resin Clock Faces', userName: 'Seema Desai' },
    { id: 25, productName: 'Resin Photo Coasters', userName: 'Gaurav Gupta' },
    { id: 26, productName: 'Resin Letter Art', userName: 'Arjun Shukla' },
    { id: 27, productName: 'Resin Wall Clocks', userName: 'Manisha Singh' },
    { id: 28, productName: 'Resin Bar Stools', userName: 'Neeraj Pandey' },
    { id: 29, productName: 'Resin Cutting Boards', userName: 'Shikha Dubey' },
    { id: 30, productName: 'Resin River Tables', userName: 'Harish Bhardwaj' },
    { id: 31, productName: 'Resin Lamps', userName: 'Sangeeta Chopra' },
    { id: 32, productName: 'Resin Floating Shelves', userName: 'Deepak Saxena' },
    { id: 33, productName: 'Resin Drawer Pulls', userName: 'Leena Malhotra' },
    { id: 34, productName: 'Resin Wine Holders', userName: 'Mukesh Aggarwal' },
    { id: 35, productName: 'Resin Coffee Tables', userName: 'Sarika Rathi' },
    { id: 36, productName: 'Resin Nightstands', userName: 'Alok Verma' },
    { id: 37, productName: 'Resin Business Card Holders', userName: 'Vaishali Jain' },
    { id: 38, productName: 'Resin Soap Dishes', userName: 'Rohan Bhargava' },
    { id: 39, productName: 'Resin Pet Tags', userName: 'Simran Kaur' },
    { id: 40, productName: 'Resin Name Plates', userName: 'Akhil Bhatia' },
    { id: 41, productName: 'Resin Placemats', userName: 'Madhuri Naik' },
    { id: 42, productName: 'Resin Napkin Rings', userName: 'Devendra Joshi' },
    { id: 43, productName: 'Resin Cake Stands', userName: 'Jaya Pillai' },
    { id: 44, productName: 'Resin Jewelry Dish', userName: 'Santosh Rao' },
    { id: 45, productName: 'Resin Fruit Bowls', userName: 'Kalyani Patil' },
    { id: 46, productName: 'Resin Tissue Box Covers', userName: 'Rajni Iyer' },
    { id: 47, productName: 'Resin Decorative Bowls', userName: 'Ravi Shetty' },
    { id: 48, productName: 'Resin Christmas Ornaments', userName: 'Sneha Kulkarni' },
    { id: 49, productName: 'Resin Serving Spoons', userName: 'Anuj Menon' },
    { id: 50, productName: 'Resin Wine Glass Charms', userName: 'Vaibhav Chopra' },
    { id: 51, productName: 'Resin Key Holders', userName: 'Lata Mukherjee' },
    { id: 52, productName: 'Resin Fridge Magnets', userName: 'Abhishek Khatri' },
    { id: 53, productName: 'Resin Desk Nameplates', userName: 'Sujata Dixit' },
    { id: 54, productName: 'Resin Mouse Pads', userName: 'Akash Mathur' },
    { id: 55, productName: 'Resin Trivets', userName: 'Anita Bose' },
    { id: 56, productName: 'Resin Wind Chimes', userName: 'Ashok Nair' },
    { id: 57, productName: 'Resin Guitar Picks', userName: 'Ravi Rao' },
    { id: 58, productName: 'Resin Drawer Organizers', userName: 'Priti Desai' },
    { id: 59, productName: 'Resin Light Switch Covers', userName: 'Karan Sehgal' },
    { id: 60, productName: 'Resin Medals', userName: 'Anshul Bhatt' },
    { id: 61, productName: 'Resin Jewelry Displays', userName: 'Sneha Batra' },
    { id: 62, productName: 'Resin Door Signs', userName: 'Rahul Patel' },
    { id: 63, productName: 'Resin Art Frames', userName: 'Monika Arora' },
    { id: 64, productName: 'Resin Serving Platters', userName: 'Neha Malhotra' },
    { id: 65, productName: 'Resin Decorative Tiles', userName: 'Suresh Prasad' },
    { id: 66, productName: 'Resin Ashtrays', userName: 'Gayatri Nair' },
    { id: 67, productName: 'Resin Bird Feeders', userName: 'Rahul Kaur' },
    { id: 68, productName: 'Resin Tissue Holders', userName: 'Geeta Vaidya' },
    { id: 69, productName: 'Resin Desktop Organizers', userName: 'Nikhil Reddy' },
    { id: 70, productName: 'Resin Decorative Eggs', userName: 'Megha Jain' },
    { id: 71, productName: 'Resin Globe Paperweights', userName: 'Nishant Bhalla' },
    { id: 72, productName: 'Resin Bar Counters', userName: 'Namrata Yadav' },
    { id: 73, productName: 'Resin Garden Ornaments', userName: 'Pankaj Tiwari' },
    { id: 74, productName: 'Resin Vase Holders', userName: 'Arti Sahni' },
    { id: 75, productName: 'Resin Bookends', userName: 'Ankit Kapoor' },
    { id: 76, productName: 'Resin Curtain Tiebacks', userName: 'Siddharth Dutta' },
    { id: 77, productName: 'Resin Knobs', userName: 'Pranav Sharma' },
    { id: 78, productName: 'Resin Soap Pumps', userName: 'Vaibhav Singh' },
    { id: 79, productName: 'Resin Towel Rings', userName: 'Nikita Khanna' },
    { id: 80, productName: 'Resin Letter Holders', userName: 'Shreya Das' },
    { id: 81, productName: 'Resin Card Holders', userName: 'Parul Reddy' },
    { id: 82, productName: 'Resin Soap Dispensers', userName: 'Ravi Krishnan' },
    { id: 83, productName: 'Resin Cup Holders', userName: 'Simran Gulati' },
    { id: 84, productName: 'Resin Plant Waterers', userName: 'Manoj Sharma' },
    { id: 85, productName: 'Resin Pin Cushions', userName: 'Lakshmi Venkatesh' },
    { id: 86, productName: 'Resin Soap Dishes', userName: 'Prakash Nair' },
    { id: 87, productName: 'Resin Recipe Holders', userName: 'Ritu Soni' },
    { id: 88, productName: 'Resin Door Stoppers', userName: 'Aditya Rao' },
    { id: 89, productName: 'Resin Magazine Racks', userName: 'Isha Kapoor' },
    { id: 90, productName: 'Resin Toilet Roll Holders', userName: 'Sonia Chawla' },
    { id: 91, productName: 'Resin Laptop Stands', userName: 'Sagar Arora' },
    { id: 92, productName: 'Resin Computer Mouse Pads', userName: 'Amit Singh' },
    { id: 93, productName: 'Resin Charging Stations', userName: 'Swati Ahuja' },
    { id: 94, productName: 'Resin Tablet Holders', userName: 'Vivek Narang' },
    { id: 95, productName: 'Resin Cup Coasters', userName: 'Priyanka Menon' },
    { id: 96, productName: 'Resin Wine Coasters', userName: 'Anuj Desai' },
    { id: 97, productName: 'Resin Coffee Coasters', userName: 'Shruti Nambiar' },
    { id: 98, productName: 'Resin Desk Lamps', userName: 'Kunal Roy' },
    { id: 99, productName: 'Resin Pen Holders', userName: 'Arjun Sen' },
    { id: 100, productName: 'Resin Stacking Trays', userName: 'Shivani Kapoor' },
  ]);

  const getRandomOrder = () => {
    const availableOrders = fakeOrders.filter(order => !recordShowDone.includes(order.id));

    if (availableOrders.length === 0) {
      console.log('All orders have been shown.');
      return;
    }

    const randomOrder = availableOrders[Math.floor(Math.random() * availableOrders.length)];

    // console.log('Random order:', randomOrder);
    setDisplayData(randomOrder)
    setShowAlert(true)
    setRecordShowDone(prevState => [...prevState, randomOrder.id]);
  };

  useEffect(() => {
    const intervalId = setInterval(getRandomOrder, 180000);

    return () => clearInterval(intervalId);
  }, [fakeOrders, recordShowDone]);

  return (
    <>
      <OrderAlerts open={showAlert} setOpen={setShowAlert} data={displayData} />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/all-categories' element={<AllCategories />} />
        <Route path='/all-products' element={<AllProducts />} />
        <Route path="/category-product/:id" element={<SelectedCategoryProducts />} />
        <Route path="/sub-category-product/:id" element={<SelectedSubCategoryProducts />} />
        <Route path="/related-products" element={<RelatedProducts />} />
        <Route path='/product-detail/:id' element={<ProductDetail />} />
        <Route path='/cart' element={<Cart />} />
        <Route path='/checkout' element={<Checkout />} />
        <Route path='/wishlist' element={<Wishlist />} />
        <Route path='/my-profile' element={<MyProfile />} />
        <Route path='/my-orders' element={<MyOrders />} />
        <Route path='/my-order-detail/:id' element={<MyOrderDetail />} />
        <Route path='/edit-profile' element={<EditProfile />} />
        <Route path='/login' element={<Login />} />
        <Route path='/forget-password' element={<ForgetPassword />} />
        <Route path='/help-center' element={<HelpCenter />} />
        <Route path='/mobile-application' element={<MobileApplication />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/shipping-and-delivery' element={<ShippingAndDelivery />} />
        <Route path='/terms-and-condition' element={<TermsAndCondition />} />
        <Route path='/return-and-refund-policy' element={<ReturnAndRefundPolicy />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/contact-us' element={<ContactUs />} />

        {/* Protected pages */}
        <Route path='/studio-sasvat/dashboard' element={<DashboardIndex />} />
        <Route path='/studio-sasvat/users' element={<UserList />} />
        <Route path='/studio-sasvat/user-detail/:id' element={<UserAllOrders />} />
        <Route path='/studio-sasvat/categories' element={<CategoryList />} />
        <Route path='/studio-sasvat/category-add' element={<CategoryForm />} />
        <Route path='/studio-sasvat/category-edit/:id' element={<CategoryForm />} />
        <Route path='/studio-sasvat/sub-categories' element={<SubCategoryList />} />
        <Route path='/studio-sasvat/sub-categories-by-category/:id' element={<SubCategoryList />} />
        <Route path='/studio-sasvat/sub-category-add' element={<SubCategoryForm />} />
        <Route path='/studio-sasvat/sub-category-edit/:id' element={<SubCategoryForm />} />
        <Route path='/studio-sasvat/products' element={<ProductList />} />
        <Route path='/studio-sasvat/product-add' element={<ProductForm />} />
        <Route path='/studio-sasvat/product-edit/:id' element={<ProductForm />} />
        <Route path='/studio-sasvat/shipping-charges' element={<ShippingChargesList />} />
        <Route path='/studio-sasvat/coupons' element={<CouponList />} />
        <Route path='/studio-sasvat/coupon-add' element={<CouponForm />} />
        <Route path='/studio-sasvat/coupon-edit/:id' element={<CouponForm />} />
        <Route path='/studio-sasvat/main-banner' element={<MainBannerList />} />
        <Route path='/studio-sasvat/main-banner-add' element={<MainBannerForm />} />
        <Route path='/studio-sasvat/secondary-banner' element={<SecondaryBannerList />} />
        <Route path='/studio-sasvat/secondary-banner-add' element={<SecondaryBannerForm />} />
        <Route path='/studio-sasvat/orders' element={<OrderList />} />
        <Route path='/studio-sasvat/order-detail/:id' element={<OrderDetail />} />
      </Routes>
      <Footer />
      <SiteRatingButton />
    </>
  );
}

export default App;
