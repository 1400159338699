/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import AdminNavbar from '../../../Components/Layout/AdminNavbar'
import AdminBottomNavigation from '../../../Components/Layout/AdminBottomNavigation'
import { Box, Button, IconButton, Modal, TextField, Tooltip, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2';
import FullPageLoader from '../../../Components/Loaders/FullPageLoader'
import AdminAuth from '../../../Components/Authentication/AdminAuth'
import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import { createShippingCharge, deleteShippingCharge, getShippingCharge, getShippingCharges, resetShippingChargeState, updateShippingCharge } from '../../../Features/ShippingCharge/ShippingChargeSlice'



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    minWidth: '300px',
    maxWidth: '500px',
    bgcolor: 'background.paper',
    p: 4
}

const ShippingChargesList = () => {
    
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const fields = {
        minimumCartValue: '',
        shippingCharge: '',
    }

    const [fullPageLoading, setFullPageLoading] = useState(true)
    const [list, setList] = useState([])
    const [showFormModal, setShowFormModal] = useState(false)
    const [updateId, setUpdateId] = useState('')
    const [data, setData] = useState(fields)

    const { shippingCharges, responseStatus, responseMessage } = useSelector(state => state.shippingCharges)

    const handleDelete = (imageId) => {
        const isConfirmed = window?.confirm("Are you sure you want to delete this shpping charge ?")

        if (isConfirmed) {
            setTimeout(() => {
                setFullPageLoading(true)
                dispatch(deleteShippingCharge(imageId))
            }, 500)
        }
    }

    const handleUpdate = (id) => {
        setUpdateId(id)
        setTimeout(() => {
            dispatch(getShippingCharge(id))
            setFullPageLoading(true)
        }, 300)
    }

    const handleFormModalClose = () => {
        setUpdateId('')

        setData({
            minimumCartValue: '',
            shippingCharge: '',
        })

        setShowFormModal(false)
    }

    const handleInput = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        setTimeout(() => {
            setFullPageLoading(true)
            if (updateId == '') {
                dispatch(createShippingCharge(data))
            } else {
                const shippingCHargeData = {
                    id: updateId,
                    minimumCartValue: data?.minimumCartValue,
                    shippingCharge: data?.shippingCharge,
                }
                dispatch(updateShippingCharge(shippingCHargeData))
            }
        }, 300)
    }

    useEffect(()=>{
        setTimeout(() => {
            setFullPageLoading(false)
        }, 1000);
        window.scrollTo(0, 0)
    },[])

    useEffect(() => {
        dispatch(getShippingCharges());
    }, [dispatch]);

    useEffect(()=>{
        if(responseStatus == 'success' && responseMessage == 'Get All'){
            setList(shippingCharges?.data)
            setTimeout(() => {
                setFullPageLoading(false)
            }, 1000);
        }
        if(responseStatus == 'success' && responseMessage == 'Get Single'){
            setData(shippingCharges?.data)
            setTimeout(() => {
                setShowFormModal(true)
                setFullPageLoading(false)
            }, 1000);
        }
        if(responseStatus == 'success' && (responseMessage == 'Shipping Charge created successfully' || responseMessage == 'Shipping Charge updated successfully')){
            handleFormModalClose()
            Swal.fire({
                icon: "success",
                title: "Congrats",
                text: responseMessage
            });

            dispatch(getShippingCharges());
        }
        if(responseStatus == 'success' && responseMessage == 'Shipping Charge deleted successfully'){
            dispatch(getShippingCharges());
        }
        if(responseStatus == 'rejected' && responseMessage != '' && responseMessage != null){
            setFullPageLoading(false)
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: responseMessage
            });
            setTimeout(() => {
                dispatch(resetShippingChargeState())
            }, 1000);
        }
    },[shippingCharges, responseStatus, responseMessage])

    return (
        <>
            <AdminAuth />
            { fullPageLoading && <FullPageLoader /> }
            <AdminNavbar />
            <div className="container mt-5">
                <div className="row pt-5">
                    <div className="col-md-12">
                        <div className='d-flex flex-wrap align-items-center justify-content-between mb-3'>
                            <h6>SHIPPING CHARGES LIST</h6>
                            <Button type='button' variant="outlined" className='px-4 border-primary text-primary' size='small' onClick={()=>setShowFormModal(true)}><AddIcon fontSize='small' />&nbsp;ADD NEW</Button>
                        </div>
                        <div className='bg-white p-3 custom-box-shadow'>
                            <div style={{ overflowX: 'auto' }}>
                                <table className='table table-bordered table-hover'>
                                    <thead>
                                        <th className='p-2 text-nowrap'>#</th>
                                        <th className='p-2 text-nowrap'>MINIMUM CART VALUE</th>
                                        <th className='p-2 text-nowrap'>SHIPPING CHARGE</th>
                                        <th className='p-2 text-nowrap'>ACTION</th>
                                    </thead>
                                    <tbody>
                                        {
                                            Array?.isArray(list) && list?.length > 0 ?
                                                Array?.isArray(list) && list?.map((val,key)=>(
                                                    <tr key={key}>
                                                        <td className='p-2 text-nowrap'>{key+1}.</td>
                                                        <td onClick={()=>handleUpdate(val?._id)}>{val?.minimumCartValue}</td>
                                                        <td onClick={()=>handleUpdate(val?._id)}>{val?.shippingCharge}</td>
                                                        <td className='p-2 text-nowrap'>
                                                            <Tooltip title="Edit" onClick={()=>handleUpdate(val?._id)}>
                                                                <IconButton>
                                                                    <CreateIcon fontSize='small' className='text-primary' />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Delete" onClick={()=>handleDelete(val?._id)}>
                                                                <IconButton>
                                                                    <DeleteIcon fontSize='small' className='text-primary' />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </td>
                                                    </tr>
                                                ))
                                            :
                                                <tr>
                                                    <td colSpan={4} className='text-center'>No Record Found</td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AdminBottomNavigation />

            <Modal
                open={showFormModal}
                onClose={handleFormModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-description">
                        <div className='d-flex align-items-center justify-content-between mb-3'>
                            <Typography sx={{ fontWeight: 'bold', color: 'grey' }}>Shipping Charge From</Typography>
                            <CloseIcon sx={{ color: 'grey', my: 1, float: 'right', cursor: 'pointer' }} onClick={handleFormModalClose} />
                        </div>
                        <form onSubmit={handleSubmit}>
                            <TextField 
                                type='number'
                                id="outlined-basic" 
                                label="Minimum Cart Value" 
                                className='w-100 mb-3'
                                size='small'
                                sx={{ mb: 3 }}
                                variant="outlined" 
                                name='minimumCartValue'
                                value={data?.minimumCartValue}
                                onChange={handleInput}
                                required 
                            />
                            <TextField 
                                type='number'
                                id="outlined-basic" 
                                label="Shipping Charge" 
                                className='w-100 mb-3'
                                size='small'
                                sx={{ mb: 3 }}
                                variant="outlined" 
                                name='shippingCharge'
                                value={data?.shippingCharge}
                                onChange={handleInput}
                                required 
                            />
                            <Button type='submit' variant="contained" className='px-4 bg-button-primary' size='small'><SaveOutlinedIcon fontSize='small' />&nbsp;&nbsp;{ updateId == '' ? 'SAVE' : 'UPDATE' }</Button>
                        </form>
                    </Typography>
                </Box>
            </Modal>
        </>
    )
}

export default ShippingChargesList